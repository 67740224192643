/* eslint-disable */
import React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';

const Terms = () => (
  <Layout>
    <SEO
      title="Terms of Use for Code Championship"
      description="Details for how to use Code Championship and rules for the platform."
      keywords={['terms', 'terms of use', 'terms of service']}
    />
    <h1>Code Championship Terms of Use</h1>
    <div className="stripe-hr thin" />
    <div class="info-outer inner">
      <div class="inner info-inner">
        <section>
          <p>
            <i>The Code Championship Terms of Use was last updated: August 27, 2020</i>
          </p>
        </section>
        <section id="user-agreement">
          <span class="nav-spacer">
          </span>
          <h3>1. User Agreement</h3>
          <p>1.1 These Terms of Use constitute an agreement between you and <Link to="/abamath/">Abamath LLC</Link> that governs your use of <Link to="/">codechampionship.com</Link> and all associated services. Please read the Terms of Use carefully. By using Code Championship you affirm that you have read, understood, and accepted the terms and conditions in the Terms of Use. If you do not agree with any of these conditions, please do not use Code Championship.</p>
          <p>1.2 Your privacy is important to us. Please read our <Link to="/terms/privacy">Privacy Policy</Link>, which identifies how Abamath LLC uses, collects, and stores information it collects through the Services. By using Code Championship, you additionally agree that you are comfortable with Code Championship's Privacy Policy.</p>
          <p>1.3 Abamath LLC may change the Terms of Use from time to time. You can always find the latest version of the Terms of Use at <Link to="/terms/">https://www.codechampionship.com/terms</Link>. The date of the most recent revisions will appear on this page. Your continued use of the Services constitutes your acceptance of any changes to or revisions of the Terms of Use.</p>
        </section>
        <section id="tournament-creation">
          <span class="nav-spacer">
          </span>
          <h3>2. Tournament Registration, Tournament Creation, and Maintenance</h3>
          <p>2.1 In order to use some features of the Services, you will need to provide Personal Information to Code Championship. When registering for events, you will be asked to provide certain personal information, such as your first name, last name, email address (or your parent or guardian's email address if you are under 18 years old), and possibly additional information relevant to a specific event (like gender, grade in school, or which school you attend). Please see Code Championship's <Link to="/terms/privacy">Privacy Policy</Link> for Code Championship's data retention and usage policies.</p>
          <p>2.2 You are responsible for keeping your secret keys secure. You are solely responsible for any use of your projects or tournaments, even if used by another person. If any use of your projects or tournaments violates the Terms of Service, they may be suspended or deleted.</p>
          <p>2.3 You may not use another person's Code Championship tournament without permission.</p>
          <p>2.4 If you have reason to believe that a project or tournament is no longer secure (for example, in the event of a loss, theft, or unauthorized disclosure of your secret key), notify us at help@codechampionship.com.</p>
        </section>
        <section id="rules-of-usage">
          <span class="nav-spacer">
          </span>
          <h3>3. Rules of Usage</h3>
          <p>3.1 Abamath LLC supports freedom of expression. However, Code Championship is intended for a wide audience, and some content is inappropriate for the Code Championship community. You may not use the Code Championship service in any way, that:</p>
          <ol>
            <li>Promotes bigotry, discrimination, hatred, or violence against any individual or group;</li>
            <li>Threatens, harasses, or intimidates any other person, whether that person is a Code Championship user or not;</li>
            <li>Contains foul language or personal attacks;</li>
            <li>Contains sexually explicit or graphically violent material;</li>
            <li>Provides instructions on how to commit illegal activities or obtain illegal products;</li>
            <li>Asks any other user for personally identifying information, contact information, or passwords; or</li>
            <li>Exposes any others person's personally identifying information, contact information, or passwords without that person's permission.</li>
          </ol>
          <p>3.3 You agree to comply with all applicable laws and regulations when you use Code Championship. You may not use Code Championship in any unlawful way, including to harass, stalk, or defame any other person.</p>
          <p>3.4 You may not impersonate, imitate or pretend to be somebody else when using the Services.</p>
          <p>3.5 You agree not to use Code Championship in any way intended to disrupt the service, gain unauthorized access to the service, or interfere with any other user's ability to use the service. Prohibited activities include, but are not limited to:<ol>
            <li>Posting content deliberately designed to crash the Code Championship website or editor;</li>
            <li>Linking to pages containing viruses or malware;</li>
            <li>Altering projects that belong to others;</li>
          </ol>
          </p>
          <p>3.6 You agree not to post links to any content outside of the Code Championship website, if to do so would violate any part of the Terms of Use.</p>
        </section>
        <section id="user-content">
          <span class="nav-spacer">
          </span>
          <h3>4. User-Generated Content and Licensing</h3>
          <p>4.1 For the purposes of the Terms of Use, "user-generated content" includes any projects that a user submits (or saves) to Code Championship.</p>
          <p>4.2 All user-generated content you submit to Code Championship is submitted to Code Championship with an unlimited license. This allows Abamath LLC to display, distribute, and reproduce your content on the Code Championship website, through social media channels, and elsewhere. If you do not want Code Championship to use your content, then do not use Code Championship.</p>
          <p>4.3 You may only submit user-generated projects that were created with the Code Championship website editor.</p>
          <p>4.4 Although Abamath LLC requires all users to comply with these Terms of Use, some inappropriate user-generated content may be submitted and displayed on the Code Championship website. You understand that when you use Code Championship you may be exposed to user-generated content that you find objectionable or offensive. If you see any content that violates the Community Guidelines or Terms of Use, please let us know by sending an email to support@codechampionship.com. You only need to report an item once. Abamath LLC reviews reported content every day.</p>
          <p>4.5 In addition to reviewing reported user-generated content, Abamath LLC reserves the right, but is not obligated, to monitor all uses of the Code Championship service. Abamath LLC may edit, move, or delete any content that violates the Terms of Use or Community Guidelines, without notice.</p>
          <p>4.6 All user-generated content is provided as-is. Abamath LLC makes no warranties about the accuracy or reliability of any user-generated content available through Code Championship and does not endorse 3rd-Party tournament events or vet or verify information posted in connection with said events. Abamath LLC does not endorse any views, opinions, or advice expressed in user-generated content. You agree to relieve Abamath LLC of any and all liability arising from your user-generated content and from tournaments you may organize or host.</p>
        </section>
        <section id="code-championship-content">
          <span class="nav-spacer">
          </span>
          <h3>5. Code Championship Content and Licensing</h3>
          <p>5.1 Abamath LLC owns and retains all rights in and to the Code Championship code, the design, functionality, and architecture of Code Championship, and any software or content provided through Code Championship (collectively "the Code Championship IP"). If you want to use Code Championship in a way that is not allowed by these Terms of Use, you must first contact Abamath LLC. Except for any rights explicitly granted under these Terms of Use, you are not granted any rights in and to any Code Championship IP.</p>
        </section>
        <section id="dmca">
          <span class="nav-spacer">
          </span>
          <h3>6. Digital Millennium Copyright Act (DMCA)</h3>
          <p>6.1 If you are a copyright holder and believe that content on Code Championship violates your rights, you may send a DMCA notification to copyright@codechampionship.com.</p>
          <p>6.2 If you are a Code Championship user and you believe that your content did not constitute a copyright violation and was taken down in error, you may send a notification to copyright@codechampionship.com. Please include:<ul>
            <li>Your email address;</li>
            <li>The specific content you believe was taken down in error (including project or tournament number); and</li>
            <li>A brief statement of why you believe there was no copyright violation (e.g., the content was not copyrighted, you had permission to use the content, or your use of the content was a "fair use").</li>
          </ul>
          </p>
        </section>
        <section id="termination">
          <span class="nav-spacer">
          </span>
          <h3>7. Suspension and Termination of Projects and Tournaments</h3>
          <p>7.1 Code Championship has the right to suspend your project or tournament for violations of the Terms of Use. Repeat violators may have their project or tournament deleted. Abamath LLC reserves the sole right to determine what constitutes a violation of the Terms of Use. Abamath LLC also reserves the right to terminate any project or tournament used to circumvent prior enforcement of the Terms of Use.</p>
          <p>7.2 If you want to delete or temporarily disable your project or tournament, please email help@codechampionship.com.</p>
        </section>
        <section id="third-party">
          <span class="nav-spacer">
          </span>
          <h3>8. Third Party Websites</h3>
          <p>8.1 Content on Code Championship, including user-generated content, may include links to third party websites. Abamath LLC is not capable of reviewing or managing third party websites, and assumes no responsibility for the privacy practices, content, or functionality of third party websites. You agree to relieve Abamath LLC of any and all liability arising from third party websites.</p>
        </section>
        <section id="indemnification">
          <span class="nav-spacer">
          </span>
          <h3>9. Indemnification</h3>
          <p>You agree to indemnify Abamath LLC, Code Championship, and all their affiliates, employees, faculty members, fellows, students, agents, representatives, third party service providers, and partners (all of which are "Abamath Entities"), and to defend and hold each of them harmless, from any and all claims and liabilities (including attorneys' fees) arising out of or related to your breach of the Terms of Service or your use of Code Championship.</p>
          <p>For federal government agencies, provisions in the Terms of Use relating to Indemnification shall not apply to your Official Use, except to the extent expressly authorized by federal law. For state and local government agencies in the United States, Terms of Use relating to Indemnification shall apply to your Official Use only to the extent authorized by the laws of your jurisdiction.</p>
        </section>
        <section id="disclaimer">
          <span class="nav-spacer">
          </span>
          <h3>10. Disclaimer of Warranty</h3>
          <p>
            <b>You acknowledge that you are using Code Championship at your own risk. Code Championship is provided "as is," and the Abamath Entities hereby expressly disclaim any and all warranties, express and implied, including but not limited to any warranties of accuracy, reliability, title, merchantability, non-infringement, fitness for a particular purpose or any other warranty, condition, guarantee or representation, whether oral, in writing or in electronic form, including but not limited to the accuracy or completeness of any information contained therein or provided by Code Championship. Without limiting the foregoing, the Abamath Entities disclaim any and all warranties, express and implied, regarding user-generated content and tournament events. The Abamath Entities and their third party service providers do not represent or warrant that access to Code Championship will be uninterrupted or that there will be no failures, errors or omissions or loss of transmitted information, or that no viruses will be transmitted through Code Championship services.</b>
          </p>
        </section>
        <section id="liability">
          <span class="nav-spacer">
          </span>
          <h3>11. Limitation of Liability</h3>
          <p>
            <b>The Abamath Entities shall not be liable to you or any third parties for any direct, indirect, special, consequential or punitive damages of any kind, regardless of the type of claim or the nature of the cause of action, even if Abamath LLC has been advised of the possibility of such damages. Without limiting the foregoing, the Code Championship Entites shall have no liability to you or any third parties for damages or harms arising out of user-generated content or tournament events.</b>
          </p>
        </section>
        <section id="jurisdiction">
          <span class="nav-spacer">
          </span>
          <h3>12. Jurisdiction</h3>
          <p>Code Championship is offered by Abamath LLC from its facilities in the United States. Abamath LLC makes no representations that Code Championship is appropriate or available for use in other locations. Those who access or use Code Championship are responsible for compliance with local law.</p>
        </section>
        <section id="choice-of-law">
          <span class="nav-spacer">
          </span>
          <h3>13. Choice of Law and Venue</h3>
          <p>You agree that these Terms of Use, for all purposes, shall be governed and construed in accordance with the laws of the State of Minnesota applicable to contracts to be wholly performed therein, and any action based on, relating to, or alleging a breach of the Terms of Use must be brought in a state or federal court in Hennepin County, Minnesota. In addition, both parties agree to submit to the exclusive personal jurisdiction and venue of such courts.</p>
          <p>If you are a federal, state, or local government entity in the United States using Code Championship in your official capacity and legally unable to accept the controlling law, jurisdiction or venue clauses above, then those clauses do not apply to you. For such U.S. federal government entities, these Terms and any action related thereto will be governed by the laws of the United States of America (without reference to conflict of laws) and, in the absence of federal law and to the extent permitted under federal law, the laws of the State of Minnesota (excluding choice of law).</p>
        </section>
        <section id="choice-of-language">
          <span class="nav-spacer">
          </span>
          <h3>14. Choice of Language</h3>
          <p>If Abamath LLC provides you with a translation of the English language version of these Terms of Use, the Privacy Policy, or any other policy, then you agree that the translation is provided for informational purposes only and does not modify the English language version. In the event of a conflict between a translation and the English version, the English version will govern.</p>
        </section>
        <section id="no-waiver">
          <span class="nav-spacer">
          </span>
          <h3>15. No Waiver</h3>
          <p>No waiver of any term of these Terms of Use shall be deemed a further or continuing waiver of such term or any other term, and Abamath LLC's failure to assert any right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</p>
        </section>
        <section id="entire-agreement">
          <span class="nav-spacer">
          </span>
          <h3>16. Entire Agreement</h3>
          <p>This document constitutes the entire Terms of Use and supersedes all previous agreements with Abamath LLC relating to the use of Code Championship. Revision date: July 28, 2020.</p>
        </section>
      </div>
    </div>
  </Layout>
);

export default Terms;
